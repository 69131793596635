/* StickyNavBar.css */

/* Main navbar styles */
.navbar-custom {
    background-color: rgba(24, 25, 26, .9);
    padding: 20px;
    z-index: 10;
    position: relative;
}

.navbar-custom.open .navbar-collapse {
    background-color: transparent; /* No background on larger screens */
}

/* Navbar Brand */
.navbar-brand {
    margin-left: 25%;
}

.navbar-toggle {
    background: #cbb27c;
}

/* Navbar links */
.navbar-nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/* Style the language buttons */
.language-switcher {
    margin-top: 2%;
    display: flex;
    justify-content: flex-end;
}

.language-btn {
    background: none;
    border: none;
    color: white;
    padding: 10px;
    margin-left: 10px;
    cursor: pointer;
}

/* Style the nav links */
.nav-link {
    font-size: 14px;
    font-weight: 700;
    line-height: .8em;
    padding: 14px 10px 12px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #fff;
    white-space: nowrap;
}

/* Mobile-specific Navbar behavior */
@media (max-width: 768px) {
    .navbar-collapse {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7); /* Darker background for readability */
        transition: background-color 0.3s ease;
    }

    .navbar-nav {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .navbar-nav .nav-link {
        text-align: left;
        width: 100%;
    }

    /* Mobile menu overlay (background) */
    .mobile-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* Transparent black background */
        z-index: 9; /* To be above other content */
        cursor: pointer; /* Make it clickable */
        display: none; /* Initially hidden */
    }

    .navbar-collapse.show + .mobile-overlay {
        display: block; /* Show overlay when menu is open */
    }
}
