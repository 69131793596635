body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: grand hotel, cursive;
  font-size: 32px;
  line-height: 1em;
  margin: 0;
  color:#cbb27c;
}

h2 {
  font-family: catamaran, sans-serif;
  font-size: 32px;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #e0f8ff
}

h4 {
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #cbb27c;
}

section{
  position: relative;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: rgb(255, 255, 255);
  background-position-x: 0;
  background-position-y: 0;
  background-image: none;
  background-size: auto;
}

header span {
  font-family: grand hotel, cursive;
  font-size: 24px;
  margin: 0;
  color: #cbb27c;
}

.container-fluid{
  position: relative;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0); /* for buggy google chrome zindex */
}

.alignMiddle{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.wrapper section{
  animation: Wrapper 1s 1s forwards;
  opacity: 0;
}
